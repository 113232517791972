/* navigator style */
.work-container{

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    /* background-color: #ccc; */
    width: 100%;
    /* margin-bottom:15%; */

}

.work-items-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    background-color: #000;

}

.work-items-container .work-item {
    /* width: 48%; */
    height: 25em;
    margin: 1%;
    border:2px solid #777;
    border-radius: 5%;
    /* position: relative; */
    
}

.work-items-container .work-item .wi-body {
   display: flex;
   justify-content: center;
   width: inherit;
   height: 60%;
   position: relative;
}

.work-items-container .work-item .wi-body img {
    width: 98%;
    height: 98%;
    /* padding: 0.2em; */
    margin: 1%;
    border-radius: 5% 5% 0 0;
    background-color: #000;

}
.work-items-container .work-item .wi-body .overlay{
    position: absolute;
    left:0;
    top:0;
    opacity: 0.2;
    background-color: #000;
    z-index: 2;
    width: 100%;
    height: 100%;
    /* margin: 1%; */
    border-radius: 5%;
}

.work-items-container .work-item .wi-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    height: fit-content;
}

.work-items-container .work-item .wi-footer span.title {

    color:#fff;
    width: 100%;
    text-align: center;
    font-weight: bolder;
}

.work-items-container .work-item .wi-footer p {
   text-align: start;
   color:gold;  
   width:100%;
   margin: 0.2em;
   padding:3%;
   font-size: 0.8em;
}

.work-items-container .work-item .wi-footer p a{
    color:#fff;
    margin: auto 1em;
}
@media (max-width:540px) {
    .work-items-container{
        flex-wrap: wrap;

    }
}

/* work item tags */
.wi-tags-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5%;
  height:fit-content

}

.wi-tag {
    width: 4em;
    height: 1em;
    border-radius: 10%;
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: 1%;
    border:1px solid #fff;
    background-color: inherit;
    margin: 1%;
}

.wi-tag span.tag-label {
    color: #fff;
    margin: 1%;
    font-size: 0.6em;
}

