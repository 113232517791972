/* navigator style */
.about-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%; 
    /* margin-bottom:15%; */

}

.who-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:90%
}

.who-section div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    
}

.who-section div img {
    /* width: 30%;
    margin: 1%;
    height: -webkit-fill-available; */
    object-fit: cover;
    width: 30%;
    height: auto;
  
}
.who-section div .me-paragraph {
    width: 68%;
   
    margin: 1% ;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    justify-content: flex-start;
}

.who-section div .me-paragraph p {
    text-align: justify;
    background-color: aliceblue;
    /* padding: 5%; */
    font-size: auto;
}

.hire-section {
   
    width: 100%;
   
    margin:0.2em;
   
    padding: 0.1em;
}

.hire-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: 2%;

}

.hire-items .a-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7em;
    height: auto;
    padding: 0.3em;
    border: 1px solid #ccc;
    border-radius: 5%;
}

.hire-items .a-item img {
    width: 90%;
    height: 90%;

}

.hire-items .a-item span{
    font-size: 1em;
    margin: 0.2em;
    
}

@media (max-width: 550px) {
    .who-section div {
        flex-wrap: wrap;
    }

    .who-section div .me-paragraph, .who-section div img{
        width: 100%;
    }
}