.page-container {
    animation: showup 3s  
}

.page-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;

}

.page-header button{ 
    /* old not used */
    position: absolute;
    left: 10%;
    top:50%
}

.page-header .back-btn{
    position: absolute;
    left: 0.5%;
    top:21%;
    align-self: center;

}

@media (max-width:500px) {
    .nav-label{
        display: none;
    }
}

@keyframes showup {
    0%{
    opacity: 0;
    }     
    
    50%{
       opacity: 0.5;
    }

    100%{
      opacity: 1;
    }    
}