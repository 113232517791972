/* navigator style */
.contact-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom:15%;
    width: 100%;

}

.social-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 95%;
    margin: 2%;
    flex-wrap: wrap;
}

.social-items  .social-item {
   /* width: 10%;
   height: 10%;
   margin:1%; */
   padding:5%;
   border: 3px solid #000;
   border-radius: 50%;
   display: flex;
   justify-content: center;
}

.social-items  .social-item img {
    /* width: 86%;
    height: 86%; */
    /* margin: 10%; */
    align-self: center;
}

.mail-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:50%;
    border: 2px solid #777;
    border-radius: 5%;
    position: relative;
}

.mail-form input {
    width: 95%;
    margin: 1%;
    padding: 2%;
    border: none;
    border-bottom: 1px solid #777;
    outline: none;
    /* position: relative; */
}
.required-email{
  position: absolute;
  right:2%;
  top:3%;
  color:red
}
.required-email-rtl{
    position: absolute;
    left:2%;
    top:3%;
    color:red
  }
/* .mail-form input:after{
  content: "*";
  width:10px;
  height: 10px;
  position: absolute;
  right:2%;
  top:10%;
  color:red
} */

.mail-form textarea{
    height: 10em;
    border: none;
    outline: none;
    width: 97%;
    margin: 1%;
    padding: 1%;
    resize: none;
}

.mail-form  input[type="submit"] {
    background-color: green ;
    color:#fff;
    padding: 1%;
    margin: 1%;
    width: 10em;
    height: 3em;
    border-radius: 5%;
}
.required-body{
    position: absolute;
    right:2%;
    top:20%;
    color:red
  }
  .required-body-rtl{
    position: absolute;
    left:2%;
    top:20%;
    color:red
  }
@media (max-width:540px) {
  
    .mail-form {
        width: 90%;
    }
}