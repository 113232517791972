.site-footer
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* background-color:#fff; */

  background: #380036;
  /* background: linear-gradient(90deg, rgba(228,245,238,1) 0%, rgba(255,255,255,1) 50%, rgba(228,245,238,1) 100%); */
  padding:20px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#fff;
  /* position:fixed; */
  left:0px;
  bottom:0px;
  width: 100%;
  height: 0.5rem;

}

.copyrights-wrapper{
   border-top: #ccc;
   margin: 0.5em;
   padding: 0.5em;
}

/* .site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
} */

.copyright-text
{
  margin:0;
  color: #fff;
  font-weight: bolder;
  font-size: 0.8em;
}
