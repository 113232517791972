
:root {
  /* --nav-bg: rgb(238,174,202);
  --nav-grad:   radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); */
  
  /* --nav-bg: rgb(252, 227, 138); */
  /* --nav-grad-rad: linear-gradient(0deg, rgba(252, 227, 138, 1) 25%, rgba(243, 129, 129, 1) 74%); */
  /* --nav-grad-rad: linear-gradient(0deg, rgba(252, 227, 138, 1) 25%, rgba(243, 129, 129, 1) 74%); */
  /* --nav-grad-li:  linear-gradient(0deg, rgba(252, 227, 138, 1) 0%, rgba(243, 129, 129, 1) 74%); */

  

  --nav-bg: #0cbaba;
  --nav-grad-rad:   radial-gradient(circle, #0cbaba 0%, #380036 50%);
  --nav-grad-li: #380036;
  

}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: inherit;


}

.App-header{
  width: 100%;
  background-color: #380036;
  height: 12%;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.App-logo {
  /* height: 40vmin; */
  height: 50px;
  pointer-events: none;
  /* width: 100%; */
}
.App-header .lang {
   position: absolute;
   top:30%;
   left:0;
   /* height: 25px; */
}

.hidden{
  display: none !important;
}

.back-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  cursor: pointer;
}

.back-btn img {
  width: 3em;
  height: 3em;
  padding: 0em;
}

.back-btn span {
  font-size: 1em;
  font-weight: 700;
  padding: 0.2em;
  align-self: center;
}


/* intro overlay */

.intro-overlay {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* background-color: transparent; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #000;
  animation: fadeout1 2s 6s
}

.intro-overlay .slide {
  /* width: 49.9%; */
  width: 100%;
  background-color: #000;
  border: 0px solid #000
}

.intro-overlay .welcome {
  color:#fff;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  animation:  showup-welcome 1s, fadeout2 1s 5.5s;
  /* left: 40%; */

}


.intro-overlay .intro-logo{
  height: 50px;
  animation: logo-welcome-animation 2s 5.5s;
  position: absolute;
  top:40%;
  /* left: 41%; */
  /* font-size: 20%; */
  width: 100%;
}
.intro-overlay .intro-logo img{
  height: inherit;
}

/* .welcome-animation{
  animation: logo-welcome-animation 2s;
} */


@keyframes fadeout1 {
  0% {
    opacity: 1 ;
    display: flex;
  }

  50% {
    opacity: 0.5 ;
    display: flex;
  }

  100% {
    opacity: 0 ;
    display: none;
  }
}

@keyframes fadeout2 {
  0% {
    opacity: 1 ;
  }

  100% {
    opacity: 0 ;
    display: none;
  }
}

@keyframes logo-welcome-animation {
  0% {
    top: 40%;
    
  }

  /* 30% {
    top: 40%;

  }

  60% {
    top: 30%;
  }

  90% {
    top: 20%;

  }

  99% {
    top: 10%;
  } */

  100% {
     /* padding: 1em */
     top:0%;
     display: none;
  }
}

@keyframes showup-welcome {
  0% {
    opcaity:0;
    transform: scale(20%);
  }  

  100%{
    opacity: 1;
    transform: scale(100%);
  }
}