/* navigator style */

.index-fix{
    z-index: -1;
}



.navigator-container{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
    position: relative;
    width: 80%;
    /* margin-top: 5%; */

}


.nav-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid silver; */
    border: none;
    width: 47%;
    height: 10em;
    margin: 0.1%;
    border-radius: 5% ;
    position: relative;
}

/* .nav-item .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    background-color: gold;
    opacity: 0.2;

} */

.nav-item button, .nav-item span {
    align-self: center;
    /* position: absolute; */
    /* z-index: 10; */
    cursor: pointer;
    color:#fff;
    background: linear-gradient(rgb(252, 227, 138), rgb(243, 129, 129));
    /* background-clip: text; */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    display: inline-block;
    font-weight: bolder;
    text-shadow: 2px 2px 4px #380036;
    width: 6.25em;
    /* font-size: clamp(.5rem, -0.875rem + 8.333vw, 1rem); */
    font-size: 0.7em;

    
}


/* animations */

.navigator-container .skills{
    /* animation-duration: 3s; */
    /* animation-name: fall; */
    animation: fall 3s, putin-right 3s 3s;
    position: relative;
    background: var(--nav-bg);
    background: var(--nav-grad-rad);
   
    border-radius: 5% 0%  0% 0%  ;
}

.navigator-container .about{
    /* animation-duration: 3s; */
    /* animation-name: fall; */
    animation: fall 3s, putin-left 3s 3s;

    position: relative;
    /* z-index: 0; */
    /* border: 1px solid orange; */
    background: var(--nav-bg);
    background: var(--nav-grad-rad);

    border-radius: 0% 5% 0%  0% ;

}

.navigator-container .contact{
    /* animation-duration: 3s; */
    animation: fall 3s, putin-left 3s 3s;
    position: relative;
    background: var(--nav-bg);
    background: var(--nav-grad-rad);
    border-radius: 0% 0% 0% 5%  ;
    /* box-shadow: 0px 1px rgba(0, 0, 0, 1); */


}

.navigator-container .work{
    /* animation-duration: 3s; */
    /* animation-name: fall; */
    animation: fall 3s, putin-right 3s 3s;

    position: relative;
    background: var(--nav-bg);
    background: var(--nav-grad-rad);
    border-radius: 0% 0% 5%  0% ;
    
    /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 1); */

}

.navigator-container .skills::after{
    content: "";
    /* border:1px solid silver; */
    border-radius: 0 50% 50% 0;
    /* border-left: 1px solid #fff; */
    position: absolute;
/* 
    right: -21.9%;
    width:21.9%; */
    
    right: -20.1%;
    width:20.1%;

    height: 39%;
    top:25.5%;
    /* background-color: #fff; */
    z-index: 20;
    margin: 1px;
    background: var(--nav-bg); 
    background: var(--nav-grad-li)
}

.navigator-container .about::after{
    content: "";
    border: none;
    /* border:1px solid silver; */
    border-radius: 0 50% 50% 0  ;
    /* border-left: 1px solid #fff; */
    position: absolute;
    left: -0.7% ;
    width:20%;
    height: 40%;
    top:25%;
    background-color: #fff;
    /* background-color: orange; */

    margin: 1px;
    z-index: 0;

}
.navigator-container .about .fix-pesoudo-up-border::after{ /*not used */
    content: "";
    border:1px solid silver;
    /* border-radius: 0 50% 50% 0  ; */
    /* border-left: 1px solid #fff; */
    border: none;
    position: absolute;
    /* left: -2.1% ;
    width: 0.4em;
    height: 10em; */
    left: -2.25% ;
    width: 1.8%;
    height: 80%; 
    top:0%;
    z-index: 1;
    background-color: #fff
}

.navigator-container .contact::after{
    content: "";
    /* border:1px solid silver; */
    border-radius: 50% 0 0 50%   ;
    /* border-right: 1px solid #fff; */
    position: absolute;
    width:20%;
    height: 40%;
    
    right: -0.7% ;
    /* width: 3em;
    height: 5em; */
    top:25%;
    background-color: #fff;
    

}

.navigator-container .contact .fix-pesoudo-down-border::after{
    content: "";
    border:1px solid #fff;
    /* border-radius: 0 50% 50% 0  ; */
    /* border-left: 1px solid #fff; */
    border: none;
    position: absolute;
    right: -2.1% ;
    width: 0.4em;
    height: 10em;
    top:0%;
    z-index: 1;
    background-color: #fff;
}

.navigator-container .work::after{
    content: "";
    /* border:1px solid silver; */
    border-radius: 50% 0 0 50%   ;
    /* border-right: 1px solid #fff; */
    position: absolute;

    /* left: -21.9%;
    width:21.9%; */
    left: -19.9%;
    width:20.1%;
    
    height: 39%;
    top:25.0%;
    /* background-color: #fff; */
    z-index: 20;
    margin: 1px;
    background: var(--nav-bg);
    background: var(--nav-grad-li);

}


.animate-item-after-click{
    animation: select-item-effect 2s ;
}


@keyframes fall {
    0% {
      font-size: 20%;
      transform: scale(20%);
    }

    50% {
        font-size: 110%;
        transform: scale(50%);
      }

    100% {
        font-size: 100%;
        transform: scale(100%);
      }
}

@keyframes putin-left{
    0%{
        transform: rotate(0deg);
    }

    50%{
        transform: rotate(1deg);
    }

    75%{
        transform: rotate(-1.5deg);
    }
    
    100% {
        transform: rotate(0deg);
    }
}

@keyframes putin-right{
    0%{
        transform: rotate(0deg);
    }

    50%{
        transform: rotate(-1deg);
    }

    75%{
        transform: rotate(1.5deg);
    }
    
    100% {
        transform: rotate(0deg);
    }
}

@keyframes slidein {

    from {
       position: absolute;
       left:100%;
       /* width:300% */
    }

    75% {
        position: absolute;
       font-size:105%;
       left:25%;
       /* width:150%; */
    }

    99% {
        position: absolute;
        font-size:100%;
        left:-3%;
        /* width:150%; */
    }

    to {
       left:0%;
       position:inherit;
       /* width:100%; */
    }
}
 

 
@keyframes fly {

    0% {
        font-size: 100%;
        transform: scale(100%);
    }
    
   50% {
        font-size: 70%;
        transform: scale(50%);
      }
 /* 
    80% {
        font-size: 20%;
        transform: scale(20%);
    }
    
    90% {
        font-size: 10%;
        transform: scale(7%);
    } */

    /* 95% {
        font-size: 5%;
        transform: scale(1%);
        display: none;
    } */

    100%{
        font-size: 0%;
        transform: scale(0%);
        display: none;
    }
  
}


@keyframes shine{
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
}


@keyframes select-item-effect {
 0%{
    transform: rotate(0deg) scale(1);
    z-index:10;
    left:-1%
 }
 10%{
    transform: rotate(10deg) scale(1.1);
    z-index:10;
    left:-3%
 }   
 30%{
    transform: rotate(0deg) scale(1.1);
    z-index:10;
    left:-6%
 }
 50%{
     left:-7%;
     z-index:10;

 }
 75%{
    left:-10%;
    z-index:10;
    
 }
 100%{
    left:-100%;
    z-index:10;
    
 }
}

@keyframes select {
    0% {
        transform: rotate(0deg);
        /* position: absolute; */
    }

    10% {
        transform: rotate(20deg);

    }

    50% {
        transform: rotate(20deg);
        /* left: 30%; */
    }

    100% {
        /* top:30%; */
        transform: rotate(0deg);
        background-color: red;
    }
    
}

/* .animate-page-on-click{
    animation: shine 2s;
} */

.animate-navigator-after-click{
    animation: fly 2s 
}




