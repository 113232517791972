/* navigator style */
.skills-container{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* flex-wrap: wrap; */

    width: 100%; 
    /* margin-bottom: 0.9rem; */
    /* margin-bottom:15%; */

 

}


.be-db, .fe-mobile-desktop, .others {
    padding: 1em;
    position: relative;
}

.be-db::after, .fe-mobile-desktop::after{
    content: "";
    background-color: rgba(148,187,233,1);
    width: 70%;
    height: 3px;
    position: absolute;
    left:15%;
    top: 102%;
    margin-bottom:5px
    
}
.group-title{
   font-size: larger;
   font-weight: bold;
   color: #777;
   margin-top: 5%;
}

.group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    width: 100%;
}

.skill-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skill-item img {
    height: 5em;
    width: 5em;
    margin:0.2em;
    padding: 0.2em;
}

.skill-item span{

    color:#000;
    font-size: 1em;
}


